<template>
  <nav>
    <ul class="pagination">
      <li class="page-item" :class="currentPage === 1 ? 'disabled' : ''">
        <button class="page-link" href="#" @click="previousPage()">
          Sebelumnya
        </button>
      </li>
      <li
        class="page-item"
        :class="currentPage === totalPage ? 'disabled' : ''"
      >
        <button class="page-link" @click="nextPage()">Selanjutnya</button>
      </li>
    </ul>
    <small>Total data: {{ totalData }}</small> <br />
    <small>Halaman: {{ currentPage }} dari {{ totalPage }}</small>
  </nav>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 1,
    },
    totalData: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    nextPage() {
      this.$emit("nextPage");
    },
    previousPage() {
      this.$emit("previousPage");
    },
  },
};
</script>

<style lang="scss" scoped></style>
