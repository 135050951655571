<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
    :click-to-close="false"
  >
    <span class="modal__title">Hapus Data</span>
    <div class="modal__content px-3 py-3">
      <div class="row">
        <div class="col-12 col-md-2 d-flex justify-content-center">
          <i
            class="bi bi-exclamation-triangle"
            style="font-size: 4rem; color: antiquewhite"
          ></i>
        </div>
        <div class="col d-flex align-items-center" style="text-align: center">
          <h5>
            Yakin untuk menghapus data ini?
            <strong>Data yang dihapus tidak dapat dikembalikan.</strong>
          </h5>
        </div>
      </div>
    </div>
    <div class="modal__action">
      <button class="btn btn-danger mx-2" @click="destroy">Hapus</button>
      <button class="btn" @click="closeModal">Batal</button>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    destroy() {
      this.$emit("destroy", this.id);
    },
  },
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  max-width: 600px;
}
</style>
